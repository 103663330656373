import { useEffect, useState } from "react";
import { trackGa } from "../tracking/googleAnalytics";
import track from "../tracking/analytics";
import { ERRORS } from "../helpers/errors";
import { log } from "../helpers";

const useEventFromExt = () => {
  const [software, setSoftware] = useState("");
  useEffect(() => {
    let isEventError = false;
    try {
      const urlParams = new URL(window.location.href).searchParams;
      const {
        software,
        ec: category,
        ea: action,
        el: label = "",
      } = Object.fromEntries(urlParams);
      software && setSoftware(software);
      if (category && action && software) {
        isEventError = true;
        trackGa(action, {
          category,
          label,
          transport_type: "beacon",
        });
        track(category, action, label, null, software);
      }
    } catch (error) {
      const logTitle = isEventError
        ? ERRORS.ANALYTICS_ERRORS.ERROR_SENDING_EVENT_TO_ANALYTICS
        : ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS;
      log(error, logTitle, true);
    }
  }, []);
  return { software };
};

export default useEventFromExt;
